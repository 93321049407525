import { PromoBannerRecipientPage } from './PromoBannerRecipientPage';
import { RecipientPageHeader } from './RecipientPageHeader';
import { PublicTemplateIframe } from '../PublicTemplatePreview/PublicTemplateIframe';
import HBText from '../honeybook-ui/HBText/HBText';
import HBButton from '../honeybook-ui/HBButton/HBButton';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import { ScreenMode } from './ScreenMode';
import { useRecipientPage } from './useRecipientPage';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useTranslation } from 'react-i18next';
import { useRecipientPageContext } from './context/recipient-page-context';

export const RecipientPage = () => {
    const {
        handleGetTemplate,
        handlePreviewTemplate,
        onViewSelect,
        signupRedirectUrl,
        screenMode: view,
        iframeContainerRef,
        isIframeLoaded
    } = useRecipientPage();

    const {
        templateData: {
            publisher: { full_name },
            title
        }
    } = useRecipientPageContext();
    const { largeUp } = useBreakpoint();
    const { t } = useTranslation();

    const actions = (
        <div
            className={`template-preview__recipient-page__content__template-details-container__actions ${view}`}
        >
            <HBButton
                isLoading={!isIframeLoaded}
                onClick={handlePreviewTemplate}
                buttonStyle="plain-primary"
                text={t('actions.previewCta')}
            />
            <RegistrationForm
                shouldShowButtonLoader={!isIframeLoaded}
                customClass={`get-template ${!isIframeLoaded ? 'loading' : ''}`}
                buttonText={t('actions.getTemplateCta')}
                source="template-preview"
                onSubmitClick={handleGetTemplate}
                signupRedirectUrl={signupRedirectUrl}
            />
        </div>
    );

    return (
        <>
            <div className="template-preview__recipient-page">
                <PromoBannerRecipientPage />
                <RecipientPageHeader onViewSelect={onViewSelect} view={view} />
                <div className="template-preview__recipient-page__content">
                    <div
                        className={`template-preview__recipient-page__content__iframe-preview ${view}`}
                    >
                        <div
                            ref={iframeContainerRef}
                            className={`template-preview__recipient-page__content__iframe-preview__container ${view}`}
                        >
                            <PublicTemplateIframe />
                        </div>
                    </div>
                    {!largeUp && actions}
                    <div className="template-preview__recipient-page__content__template-details-container">
                        <div className="template-preview__recipient-page__content__template-details-container__content">
                            <HBText
                                tag="span"
                                type="title-s"
                                customClass="title"
                            >
                                {t('templateDetails.title', {
                                    creator: full_name
                                })}
                            </HBText>
                            <HBText
                                tag="span"
                                type="subtitle-s"
                                customClass="template_name"
                            >
                                {title}
                            </HBText>
                            <HBText
                                tag="span"
                                type="body-s"
                                customClass="description"
                            >
                                {t('templateDetails.description')}
                            </HBText>
                        </div>
                        {!largeUp && (
                            <ScreenMode
                                view={view}
                                onViewSelect={onViewSelect}
                            />
                        )}
                        {largeUp && actions}
                    </div>
                </div>
            </div>
        </>
    );
};
