import { useABTestContext } from '../../ab-tests/context/useABTestContext';
import { useRecipientPageContext } from '../RecipientPage/context/recipient-page-context';
import { RecipientPage } from '../RecipientPage/RecipientPage';
import { FullScreenPublicTemplate } from './FullScreenPublicTemplate';
import {
    usePublicTemplatePreview,
    UsePublicTemplatePreviewParams
} from './usePublicTemplatePreview';

export const PublicTemplatePreviewWrapper = ({
    clickUseTemplateSubscriber
}: UsePublicTemplatePreviewParams) => {
    const { templateData } = useRecipientPageContext();
    const {
        variantPerTest: { public_template_recipient_funnel }
    } = useABTestContext();

    usePublicTemplatePreview({ clickUseTemplateSubscriber });

    return (
        <div className="template-preview">
            {templateData!.public_configuration.is_gallery_template ||
            public_template_recipient_funnel === 'control' ? (
                <FullScreenPublicTemplate />
            ) : (
                <RecipientPage />
            )}
        </div>
    );
};
