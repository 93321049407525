import { CURRENT_REFERER_STORAGE_KEY } from '../constants/constants';
import DeviceService from './DeviceService';
import StorageService from './StorageService';
import UtilsService from './UtilsService';
import type { Swiper as SwiperType } from 'swiper/types';

export const AnalyticsEvents = {
    page_view: 'Page View: ',
    logged_in_user: 'Logged In User',
    button_click: 'Button Clicked: ',
    link_clicked: 'Link Clicked: ',
    error: 'Error',
    login: 'Login',
    signup: 'Signup',
    host_updated_to_event: 'Host Updated To Event',
    host_removed_from_event: 'Host Removed From Event',
    user_added_to_workspace: 'User Added To Workspace',
    reset_password_success: 'Reset Password Success',
    reset_password_request: 'Reset Password Request',
    terms_of_service_viewed: 'Terms Of Service Viewed',
    terms_of_service_accepted: 'Terms Of Service Accepted',
    invite_form_event_page_submitted:
        'Invite From Marketing Event Page Submitted',
    invite_request_form_submitted: 'Request Invite Form Submitted',
    open_multiple_stage_intake_form: 'Open multiple stage intake form',
    // Use .trackClick, not .track to produce 'click: start trial'
    start_trial: 'start trial',
    public_profile: 'public profile',
    creative_directory: 'creative directory',
    start_free_trial: 'start free trial',
    watch_video: 'watch video',
    watch_video_scroll: 'watch video scroll',
    faq_section: 'faq section',
    educator: 'educator',
    webinar: 'register',
    webinarNav: 'webinar nav button',
    press_link: 'Press Link',
    text_links_block: 'text link block',
    scroll_down_separator: 'scroll down separator',
    pricing_toggle: 'pricing toggle',
    pricing_table_toggle: 'toggle expand pricing table',
    feature_value_accordion_toggle: 'feature value block accordion toggle',
    ab_test_tool_error: 'ab test tool error',
    geo_location_request_timeout: 'error: geo location timeout',
    geo_location_request_error: 'error: geo location error',
    ab_test_marketing_started: 'ab_test_started',
    register_utm: 'intake form utm_source debug',
    on_boarding_non_usa_warning_view: 'On Boarding non usa warning view',
    on_boarding_non_usa_warning_continue:
        'On Boarding non usa warning. I am US based',
    on_boarding_non_usa_warning_cancel: 'On Boarding non usa warning. Cancel',
    on_boarding_form_submitted: 'On Boarding Form Submitted',
    on_boarding_form_submit_error: 'On Boarding Form Submit error',
    on_boarding_international_view: 'International Modal View',
    on_boarding_international_continue: 'International Modal Continue',
    on_boarding_international_cancel: 'International Modal Cancel',
    on_boarding_international_accept_view: 'International Accept View',
    on_boarding_international_accept_close: 'International Accept Close',

    dollar_promo_banner_close: 'Dollar promo banner Close',
    welcome20_promo_banner_close: 'Welcome20 promo banner Close',
    welcome50_promo_banner_close: 'Welcome50 promo banner Close',
    welcome90_promo_banner_close: 'Welcome90 promo banner Close',
    close_popup: 'Close popup',

    feature_checklist_select_item: 'toggle: intent_button',

    on_registration_form_click: 'click: submit email',
    on_single_button_trial_click: 'click: start trial',
    registration_form_invalid: 'error: Registration form data invalid',
    registration_validation_error: 'Registration validation error',
    registration_validation_redirect: 'Registration validation redirect',
    sso_user_exists: 'server: user is already hb user',
    intake_validation_redirect: 'Intake validation redirect',
    registration_invalid_domain: 'Registration Validation invalid domain',
    company_info_submit_click: 'click: submit company info',

    intake_form_rts_load: 'Intake Form RTS Load',
    intake_form_rts_click_submit: 'click: intake form RTS submit',
    intake_form_rts_click_submit_error: 'intake form RTS submit error',
    intake_form_rts_click_submit_success: 'success: intake form RTS submitted',
    intake_form_rts_click_continue_facebook:
        'click: intake RTS - continue to facebook',

    complete_registration_form_load: 'Complete registration form load',
    complete_registration_form_click_submit:
        'click: Complete registration form submit',
    complete_registration_form_click_submit_error:
        'Complete registration form submit error',
    complete_registration_form_submit_success:
        'success: Complete registration form submitted',
    educator_apply_now: 'educator apply now',
    intake_form_submitted: 'Intake Form Submitted',
    intake_form_submit_error: 'Intake Form Submit error',
    intake_modal_load: 'loaded: intake form modal',
    intake_modal_click_submit: 'click: intake form modal submit',
    intake_modal_click_close: 'click: intake form modal close',
    intake_modal_submit_success: 'success: intake form modal submitted',
    intake_modal_toggle_tos_approval: 'toggle tos approval',

    canada_waiting_list_submit: 'submit to canada waiting list',
    canada_waiting_list_submit_success:
        'success: submit to canada waiting list',
    canada_waiting_list_submit_error: 'error: submit to canada waiting list',

    canada_confirmation_modal_shown: 'loaded: canada confirmation modal',
    canada_confirmation_modal_closed: 'closed: canada confirmation modal',
    canada_confirmation_modal_submit: 'click: canada invite friend',
    canada_confirmation_modal_submit_success:
        'success: invite friend to canada waiting list',
    canada_confirmation_modal_submit_error:
        'error: invite friend to canada waiting list',

    website_send_example_proposal: 'submit email',
    website_send_example_proposal_error: 'error: send example proposal',
    credit_card_added: 'Credit Card Added',
    event_cover_photo_changed: 'Event Cover Photo Changed',
    workspace_file_cover_photo_changed: 'Workspace File Cover Photo Changed',
    contract_signature_description_changed:
        'Contract Signature Description Changed',
    contract_signed: 'Contract Signed',
    contract_seen: 'Contract Seen',
    proposal_seen: 'Proposal Seen By Client',
    payment_paid: 'Payment Submitted',
    company_updated: 'Company Updated',
    contract_selected: 'Contract Selected',
    contract_line_edited: 'Contract Line Edited',
    contract_saved: 'Contract Saved',
    agreement_open_input_field_popup: 'Agreement Input Field Popup Open',
    agreement_add_input_field: 'Agreement Add Input Field',
    agreement_change_input_field: 'Agreement Change Input Field',
    agreement_add_payments_region: 'Agreement Add Payments Region',
    agreement_add_packages_region: 'Agreement Add Packages Region',
    agreement_add_header_region: 'Agreement Add Header Region',
    file_sent: 'File Sent',
    first_file_sent: 'First File Sent',
    file_deleted: 'File Deleted',
    workspace_deleted: 'Workspace Deleted',
    lead_mail_sent: 'Lead Mail Sent',
    show_proposal_mail_preview: 'Show Proposal Mail Preview',
    event_updated: 'Event Updated',
    event_created: 'Event Created',
    payments_container_selected: 'Payments Container Selected',
    payment_added: 'Payment Added',
    payment_updated: 'Payment Updated',
    payment_deleted: 'Payment Deleted',
    payment_reminder_sent: 'Payment Reminder Sent',
    payment_marked_as_paid: 'Payment Marked As Paid',
    package_added_to_file: 'Package Added To Workspace File',
    package_deleted_from_file: 'Package Deleted From Workspace File',
    service_deleted_from_package: 'Service Deleted From Package',
    service_added_to_package: 'Service Added To Package',
    service_deleted_from_proposal: 'Service Deleted From Proposal',
    service_added_to_proposal: 'Service Added to Proposal',
    proposal_saved: 'Proposal Saved',
    credit_card_deleted: 'Credit Card Deleted',
    proposal_description_updated: 'Proposal Description Updated',
    proposal_discount_updated: 'Proposal Discount Updated',
    proposal_tax_updated: 'Proposal Tax Updated',
    proposal_tax_type_changed: 'Proposal Tax Type Changed',
    create_workspace_file: 'Create Workspace File',
    file_title_updated_from_modal: 'File Title Updated From Modal',
    file_title_updated_from_header: 'File Title Updated From Header',
    new_version_available: 'New Version Available',
    scrolled: 'Scrolled',
    dashboard_loaded: 'Reports Loaded',
    honeybook_loaded: 'HoneyBook Loaded',
    account_created: 'Account Created',
    account_created_contacts: 'Account Created - As a Contact',
    account_created_workspace: 'Account Created - As a Workspace Member',
    account_created_v2v_contacts:
        'Vendor Account Created By Vendor - As a Contact',
    account_created_v2v_workspace:
        'Vendor Account Created By Vendor - As a Workspace Member',
    workspace_filter_by_emails: 'Filter workspace by emails',
    workspace_filter_by_files: 'Filter workspace by files',
    workspace_filter_by_payments: 'Filter workspace by payments',
    download_monthly_report: 'Download Monthly Report',
    save_contact: 'Contact Saved',
    invite_contact_success: 'Contact Invited',
    invite_contact_error: 'Error Inviting Contact',
    connect_to_google: 'User opened connect to Google popup',
    connect_to_google_successful: 'User connected to Google for contacts',
    connect_to_google_canceled:
        'User canceled connection to Google for contacts',
    signup_with_facebook: 'click: sign up with facebook',
    signup_with_facebook_successful:
        'signup with facebook finished successfully',
    already_signup_with_facebook: 'user already signup with facebook',
    signup_with_facebook_sso_details:
        'user is authenticated with facebook. original sso data is',
    signup_with_facebook_error: 'error signing up with facebook',
    facebook_sso_new_user: 'facebook first sign up',
    signup_with_google: 'click: sign up with google',
    signup_with_google_loading_popup: 'load: google choose email modal',
    signup_with_google_successful: 'signup with google finished successfully',
    signup_with_google_user_approval: 'signup with google user approval',
    signup_with_google_user_approve_permissions:
        'server: user approved google permissions',
    already_signup_with_google: 'server: chrome connected to google',
    signup_with_google_error: 'error signing up with google',
    signup_with_google_user_closed_email_chooser:
        'cancel: google choose email modal',
    signup_with_google_user_closed_popup:
        'error: signing up with google - user closed popup',
    rts_survey_user: 'rts survey user created',
    rts_survey_error: 'rts survey error',
    rts_survey_submit: 'rts survey submit',
    rts_survey_start_trial: 'rts survey start trial',
    rts_survey_user_connected: 'rts survey user connected',
    request_proposal_sent: 'Request Proposal Sent',
    workspace_name_updated: 'Workspace Name Update',
    workspace_name_update: 'Update Workspace Name',
    delete_template_permanently_confirmed: 'Delete Template Permanently',
    delete_template_permanently_failed: 'Delete Template Permanently Failed',
    bank_account_added: 'Bank Account Added',
    bank_account_deleted: 'Bank Account Deleted',
    dashboard_search_conducted: 'User conducted Search in Dashboard',
    dashboard_searched_item_clicked:
        'User Clicked on Searched Item in Dashboard',
    local_storage_disabled_error: 'local storage disabled error',
    header_navigation_button_click: 'click: header',
    header_navigation_button_hover: 'hover: header',
    footer_navigation_button_click: 'click: footer',
    intake_form_submit_header: 'click: intake form submit header',
    intake_form_submit_footer: 'click: intake form submit footer',
    landing_page_intake_submit: 'Landing page intake submit ',
    intake_email_domain_not_valid: 'Intake form error email domain not valid',
    social_media_links: 'Social media links',
    app_download_button: 'App download button',

    home_get_started_btn_bottom_section:
        'click: home get started btn bottom section',
    stored_utm_params: 'Utm params stored',
    home_video_play_click: 'testimonial video played',
    home_video_speaker_click: 'testimonial speaker selected',
    home_mobile_app_click: 'mobile app download',
    home_module_features: 'features module',
    home_module_reviews: 'reviews module',
    testimonial_carousel_navigate: 'testimonials carousel navigate',
    carousel_interaction: 'carousel interaction',
    home_hero_video: 'homepage hero video',
    interactive_files_video_modal: 'interactive files video modal',
    interactive_files_scroll: 'interactive files scroll into view',
    photographers_landing_page_hero_video:
        'photographers landing page hero video',

    contract_template_hero_scroll_to: 'Try it for free',
    value_props_hero_video: 'Value Props Toggle Video Modal',
    value_props_hover: 'Value Props Hover',

    template_preview_slider_action: 'template preview slider action',

    template_load_to_hb: 'load template to honeybook',
    lead_invoice_download: 'lead invoice download',
    lead_invoice_add_item: 'lead invoice item add',
    lead_invoice_delete_item: 'lead invoice item delete',
    lead_invoice_jump_to_editor: 'lead invoice jump to editor',
    lead_invoice_learn_more: 'lead invoice learn more',
    lead_invoice_faq_title: 'lead invoice faq title',
    confirm_lead_invoice_sent_confirmation_modal:
        'confirm: lead invoice sent confirmation modal',
    lead_invoice_confirmation_modal_accept_emails:
        'lead invoice sent confirmation modal accept email',
    loaded_lead_invoice_sent_confirmation_modal:
        'loaded: lead invoice sent confirmation modal',
    loaded_lead_invoice_sent_error_modal:
        'loaded: lead invoice sent error modal',
    lead_invoice_sent_error_modal_edit: 'lead invoice sent error modal edit',
    home_video_hero_mac_play: 'Macs video played',
    template_download_modal_sent: 'Close template PDF sent modal',

    reviews_carousel_navigation: 'reviews page carousel navigation',

    biz_test_card: 'biz test HB tip card',
    biz_test_tip_link: 'biz test tip link',
    biz_test_social_sharing: 'social sharing',
    biz_test_download_photo: 'download to photos',
    biz_test_cta: 'Start biz test',
    biz_test_download_ig_template: 'download instagram template',
    biz_download_guide: 'Download guide',

    feature_grid: 'feature grid',
    feature_values_block_link: 'feature values link',
    app_store_link: 'app store link',
    discover_link_block: 'discover link block',
    testimonial_cards_block_reviews_link: 'read more reviews link',

    lp_features_hub_feature_redirect: 'feature redirect',
    lp_features_hub_feature_selected: 'feature selected',
    lp_features_hub_download_guide: 'features download guide',
    lp_features_hub_help_center_article: 'help center article',

    lp_pricing_feature: 'pricing feature',
    lp_reviews_link: 'reviews link',

    lp_invoice_template_generator_download: 'download invoice pdf',
    lp_invoice_template_generator_confirm_email: 'confirm email modal',
    lp_invoice_template_generator_upload_logo: 'upload logo',
    lp_invoice_template_generator_modal_marketing_emails:
        'toggle marketing email permission',

    lp_invoice_mission_statement_download: 'download mission statement',
    lp_invoice_mission_statement_editor: 'toggle mission statement editor',
    lp_invoice_mission_statement_send: 'send mission statement',
    lp_invoice_mission_statement_close_send_modal:
        'close send mission statement modal',
    lp_invoice_mission_statement_close_sent_modal:
        'close sent mission statement modal',
    lp_invoice_mission_statement_close_start_over_btn: 'start over',
    lp_invoice_mission_statement_start_quiz: 'open mission statement quiz',
    lp_invoice_mission_statement_close_quiz: 'close mission statement quiz',
    lp_invoice_mission_statement_quiz_prev_step:
        'mission statement quiz previous step',
    lp_invoice_mission_statement_quiz_next_step:
        'mission statement quiz next step',

    lp_email_signature_complete: 'confirm: lead signature confirmation modal',
    lp_email_signature_cancel: 'cancel: lead signature confirmation modal',
    lp_email_signature_upload_logo: 'success lead signature image upload',
    lp_email_signature_change_to_template_card:
        'lead signature template selected',
    lp_email_signature_finish: 'lead signature complete',
    lp_email_signature_tab_info: 'lead signature info selected',
    lp_email_signature_tab_social: 'lead signature social selected',
    lp_email_signature_tab_templates: 'lead signature template selected',
    lp_email_signature_faq: 'lead signature faq title',
    lp_email_signature_load_modal: 'loaded: lead signature confirmation modal',
    lp_email_signature_feature_module: 'feature module',
    lp_email_signature_social_sharing: 'social sharing',
    lp_email_signature_logo_link: 'nav logo link',
    lp_email_signature_upload_logo_failed:
        'failed to upload image (email signature)',
    lp_email_signature_create_failed: 'failed to create signature',
    lp_email_signature_fetch_failed: 'failed to fetch signature',

    lp_email_signature_mission_statement: 'mission statement lp',
    lp_email_signature_invoice_template: 'invoice template lp',

    lp_email_signature_footer: 'honeybook lp',

    lp_email_signature_modal_next_steps: 'email signature modal tip',

    lp_mission_statement_examples_carousel:
        'mission statement examples carousel',
    lp_mission_statement_get_started: 'mission statement get started',
    lp_mission_statement_next_question: 'mission statement next question',
    lp_mission_statement_validation_error:
        'error: mission statement validation',
    lp_mission_statement_server_call_error:
        'error: mission statement server call',
    lp_mission_statement_create_lead: 'mission statement create lead',
    lp_mission_statement_create_lead_success:
        'success: mission statement create lead',
    lp_mission_statement_edit_statement: 'mission statement edit statement',
    lp_mission_statement_generate_statement: 'mission statement generate ',
    lp_mission_statement_download: 'mission statement download ',
    lp_mission_statement_send_statement: 'mission statement send statement',
    lp_mission_statement_show_final_modal: 'mission statement show final modal',
    lp_mission_statement_finish_edit: 'mission statement finish edit',
    lp_mission_statement_color_changed: 'mission statement color change',
    lp_mission_statement_facebook_share: 'mission statement facebook share',
    lp_mission_statement_font_change: 'mission statement font change',
    lp_mission_statement_generated_statement: 'mission statement generated ',

    lp_member_love_read_more: 'read more',
    lp_member_love_slick_clients: 'review next',
    lp_member_love_member_selected: 'member spotlight selected',
    lp_member_love_member_redirect: 'member spotlight redirect',
    lp_member_love_blog_item: 'review redirect',
    lp_invoice_generator_hero_cta: 'try free invoices',
    lp_invoice_generator_free_invoice: 'go to invoice templates page',
    lp_invoice_generator_download_pdf: 'download invoice pdf',
    lp_invoice_generator_best_software_cta: 'honeybook for vertical',
    other_features_block: 'features module',
    blog_post: 'blog post',

    tax_calculator_submit_form: 'tax calculator submit form',
    tax_filing_block_submit_email: 'tax filing checklist submit email',
    tax_calculator_submit_email: 'tax calculator submit email',
    tax_calculator_return: 'restart tax calculator',

    mta_start_a_trial: 'mta user trial start',

    contact_us_page: 'contact us page',

    referrals_module_link: 'my benefits button',

    explore_our_free_invoices: 'explore our free invoices',
    navigate_to_online_invoices: 'navigate to online-invoices page',
    navigate_to_invoice_templates: 'navigate to invoice-templates page',

    vertical_link_to_invoices: 'link to invoice template page',
    contracts_link_to_vertical: 'link to veritical page',

    ab_test_initiated: 'AB Test Initiated: ',
    // These are fired dynamically from invoice-template block
    // Click: navigate to invoices for [vertical] page
    // Click: navigate to software for [vertical] page

    invoice_hub_hero_cta_test: 'Secondary CTA',
    lead_gen_form_submit: 'client experience lead gen form submit',

    load_template_to_honeybook: 'load template to HoneyBook',

    gated_invoice_form_submit: 'gated invoice form submit',
    marketing_site_google_gne_tap_error: 'marketing site google one tap error',
    marketing_site_google_one_tap_load: 'marketing site google one tap load',
    marketing_site_google_one_tap_display:
        'marketing site google one tap display',
    marketing_site_google_one_tap_credentials_returned_successfully:
        'marketing site google one tap credentials returned successfully',
    open_cookie_settings_banner: 'open cookie settings banner',
    remove_cookie_consent: 'remove cookie consent',
    turn_on_cookie_consent: 'turn on cookie consent',
    honeybook_logo: 'nav logo',
    social_share_buttons_click: 'button clicked',
    social_share_buttons_copy_link: 'link copied',
    esign_clear_all: 'clear esign',
    esign_download: 'download esign',
    hide_intercom: 'hide intercom',
    show_intercom: 'show intercom',
    cloudlflare_banner: 'a cloudflare banner was detected - it is now removed',
    tooltip_shown: 'tooltip shown',
    feedback_page_loaded: 'feedback page',
    feedback_form_submitted: 'feedback form submitted',
    feedback_form_cta_click: 'submit feedback',
    feedback_form_id_missing:
        'error: missing client feedback id (redirecting to home)',
    feedback_form_loading_failed:
        'error: failed to load client feedback from server (redirecting to home)',
    feedback_form_missing_review_rating: 'error: missing review rating'
} as const;

export const GTM_ANALYTICS_EVENTS = {
    cta_click: 'cta_click',
    virtual_page_view: 'virtual_pageview',
    navigation_click: 'navigation_click',
    app_download_click: 'app_download_click',
    social_interaction: 'social_interaction',
    faq_click: 'faq_click',
    tab_click: 'tab_click'
} as const;
export default class AnalyticsService {
    static shouldTrack = (): boolean =>
        process.env && process.env.GATSBY_env === 'production';
    static shouldLoadSegment = (): boolean =>
        !!(process.env && process.env.GATSBY_SegmentKey);
    static initialReferrer = UtilsService.isBrowser() && document.referrer;
    static isSegmentAvailable = (): boolean => !!window.analytics;
    static segmentDefaultIntegrations = {
        All: true,
        Mixpanel: false,
        Iterable: false,
        'Mixpanel (Actions)': false
    };
    static hbAnonymousId: string;

    static consoleEvent = (
        eventName: string,
        args: Record<string, unknown>
    ): void => {
        if (process.env.GATSBY_env === 'production') {
            return;
        }

        let consoleString = '';
        let key;
        let value;

        for (key in args) {
            if (Object.prototype.hasOwnProperty.call(args, key)) {
                value = args[key];
                consoleString += `${key}=${value};\n`;
            }
        }

        console.info(`[${eventName}]\n${consoleString}`);
    };

    static trackClick = (
        eventName: string,
        args?: Record<string, any>
    ): void => {
        this.track(`click: ${eventName}`, args);
    };

    // static trackSwiperInteraction = (
    //     swiper: SwiperType,
    //     source: string
    // ): void => {
    //     if (!swiper.autoplay.running) {
    //         this.track(AnalyticsEvents.carousel_interaction, {
    //             source
    //         });
    //     }
    // };

    private static getContextData() {
        return {
            ...UtilsService.getOsData(),
            $screen_width: window.screen.width,
            $screen_height: window.screen.height
        };
    }

    private static getDefaultProperties() {
        const DEFAULT_REFERRER = '$direct';
        let { referrer: currentReferrer } =
            StorageService.getItem(
                CURRENT_REFERER_STORAGE_KEY,
                'sessionStorage'
            ) || {};
        currentReferrer ||= this.initialReferrer;
        return {
            $initial_referrer: this.initialReferrer || DEFAULT_REFERRER,
            $initial_referring_domain:
                UtilsService.extractDomain(this.initialReferrer || '') ||
                DEFAULT_REFERRER,
            $referrer: currentReferrer || DEFAULT_REFERRER,
            current_referrer_pathname:
                UtilsService.getUrlPathname(currentReferrer) ||
                DEFAULT_REFERRER,
            $referring_domain:
                UtilsService.extractDomain(currentReferrer) || DEFAULT_REFERRER,
            ...(DeviceService.mobileDevice && {
                $device: DeviceService.mobileDevice
            }),
            screen_resolution: UtilsService.getScreenResolution(),
            'User Agent': navigator.userAgent,
            hb_anonymous_id: this.hbAnonymousId,
            ...UtilsService.getSessionUtmParams()
        };
    }

    static track = (eventName: string, args?: Record<string, any>): void => {
        const fullArgs = {
            app_path:
                window.location.pathname === '/'
                    ? '/'
                    : window.location.pathname.slice(1),
            platform: 'gatsby',
            ...args,
            ...this.getDefaultProperties()
        };

        this.reportEvent(
            eventName,
            fullArgs,
            {
                All: false,
                Mixpanel: true,
                'Mixpanel (Actions)': true
            },
            this.getContextData()
        );
    };

    static identify = (
        id: string,
        data?: Record<string, string>,
        integrations?: Record<string, boolean>
    ): void => {
        if (AnalyticsService.shouldLoadSegment()) {
            const options = {
                integrations: integrations || {
                    All: true,
                    Iterable: false
                }
            };
            UtilsService.handleThirdPartyLoad(() => {
                window.analytics.identify(id, data, options);
            }, this.isSegmentAvailable);
        }
    };

    // Segment

    static async identifyUser(
        { email, country }: Record<string, string>,
        integrations?: Record<string, boolean>
    ) {
        const userTraits: Record<string, string> = {
            email,
            country
        };

        this.consoleEvent('Segment: identifyUser', userTraits);

        if (this.isSegmentAvailable()) {
            return window.analytics.identify(
                undefined,
                userTraits,
                integrations || {}
            );
        }
    }

    static anonymizeUser() {
        this.consoleEvent('Segment: anonymizeUser', {});

        if (this.isSegmentAvailable()) {
            return window.analytics.reset();
        }
    }

    static reportEvent = (
        eventName: string,
        eventProps: Record<string, any> = {},
        integrations: Record<string, boolean> = this.segmentDefaultIntegrations,
        context = {}
    ) => {
        this.consoleEvent(eventName, eventProps);
        if (this.shouldLoadSegment()) {
            UtilsService.handleThirdPartyLoad(() => {
                window.analytics.track(eventName, eventProps, {
                    integrations,
                    ...context
                });
            }, this.isSegmentAvailable);
        }
    };

    static pageView = () => {
        if (this.shouldLoadSegment()) {
            UtilsService.handleThirdPartyLoad(() => {
                window.analytics.page(
                    undefined,
                    {},
                    {
                        integrations: this.segmentDefaultIntegrations
                    }
                );
            }, this.isSegmentAvailable);
        }
    };

    static reportIterableEvent = async (
        eventName: string,
        eventProps: Record<string, any>,
        reportingUser: Record<string, any>
    ) => {
        const integrations = {
            All: false,
            Iterable: true
        };

        this.consoleEvent(eventName, eventProps);

        if (this.isSegmentAvailable()) {
            this.anonymizeUser();
            await this.identifyUser(
                {
                    email: reportingUser.email,
                    country: reportingUser.country
                },
                integrations
            );

            return this.reportEvent(eventName, eventProps, integrations);
        }
    };

    static trackGtm(
        event: typeof GTM_ANALYTICS_EVENTS[keyof typeof GTM_ANALYTICS_EVENTS],
        args?: Record<string, any>
    ): void {
        window.dataLayer ||= [];
        window.dataLayer.push({
            event,
            page_url: window.location.href,
            ...args
        });
    }

    static overrideSegmentAnonymousId(id: string, traits = {}) {
        if (!this.isSegmentAvailable()) {
            return;
        }
        window.analytics.identify(undefined, traits, {
            anonymousId: id
        });
    }

    static handleHBAnonymousId() {
        const stableId = UtilsService.handleHBAnonymousId();
        this.hbAnonymousId = stableId;

        if (!this.shouldLoadSegment()) {
            return;
        }

        UtilsService.handleThirdPartyLoad(() => {
            this.overrideSegmentAnonymousId(this.hbAnonymousId);
        }, this.isSegmentAvailable);
    }
}
