import { useTranslation } from 'react-i18next';
import Star from '../../svg/single-star.inline.svg';
import PromoBanner from '../PromoBanner/PromoBanner';
import HBText from '../honeybook-ui/HBText/HBText';
import { useRecipientPageContext } from './context/recipient-page-context';

export const PromoBannerRecipientPage = () => {
    const {
        templateData: {
            publisher: { full_name },
            public_configuration: { offer_referral, referral_details }
        }
    } = useRecipientPageContext();
    const { t } = useTranslation();

    const shouldShowReferralDiscount =
        !!offer_referral &&
        !!referral_details &&
        !!referral_details.discount_amount &&
        !!referral_details.discount_duration;

    if (!shouldShowReferralDiscount) {
        return null;
    }
    return (
        <PromoBanner userCanDismiss={false} shouldShowBanner>
            <Star className="star-icon" />
            <HBText tag="span" type="body-s">
                {t('banner.title', {
                    creator: full_name,
                    duration: referral_details.discount_duration,
                    amount: referral_details.discount_amount
                })}
            </HBText>
        </PromoBanner>
    );
};
