import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './client-relationships-hero.scss';

const ClientRelationshipsHero = () => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    return (
        <FeatureBlockSection customClass="client-relationships-hero">
            <FeatureBlockContent customClass="client-relationships-hero__content">
                <div className="client-relationships-hero__split-layout">
                    <div className="client-relationships-hero__text-container">
                        <HBText
                            tag="h1"
                            type="title-m"
                            customClass="client-relationships-hero__title"
                        >
                            {t('clientRelationships.hero.title')}
                        </HBText>
                        <div className="client-relationships-hero__bubble-container">
                            <HBText
                                tag="p"
                                type="body-xl"
                                customClass="client-relationships-hero__bubble-text client-relationships-hero__bubble-text--first"
                            >
                                {t('clientRelationships.hero.bubbleText1')}
                            </HBText>
                            <HBText
                                tag="p"
                                type="body-xl"
                                customClass="client-relationships-hero__bubble-text client-relationships-hero__bubble-text--second"
                            >
                                {t('clientRelationships.hero.bubbleText2')}
                            </HBText>
                        </div>
                        <HBText
                            tag="p"
                            type="body-xl"
                            customClass="client-relationships-hero__subtitle"
                        >
                            {t('clientRelationships.hero.subtitle')}
                        </HBText>

                        <RegistrationForm
                            source="hero"
                            showNoCcRequired
                            size="large"
                        />
                    </div>

                    <HBMediaElement
                        customClass="client-relationships-hero__illustration"
                        name={
                            breakpoints.medium
                                ? 'marketing_site/october_gtm/oct_gtm_hero_mobile.png'
                                : 'marketing_site/october_gtm/oct_gtm_hero_desktop.png'
                        }
                        lazyLoad={false}
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default ClientRelationshipsHero;
