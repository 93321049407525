import { memo } from 'react';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import HBText from '@honeybook-ui/HBText/HBText';
import { useTranslation } from 'react-i18next';
import useReferral from '../../../hooks/useReferral';
import { Coupons } from '../../../constants/promoCodes';

import './promo-block-left.scss';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

interface Props {
    title: string;
    subtitle?: React.ReactNode | string;
    titleImage?: string;
    customClass?: string;
    ctaText?: string;
    source: string;
    customNoCcText?: boolean;
    buttonStyle?;
    promoCode?: string;
    darkTheme?: boolean;
    tosUrl?: string;
    customTosText?: string;
}

const PromoBlockLeft = ({
    title,
    subtitle,
    titleImage,
    ctaText,
    customClass = '',
    source,
    customNoCcText = false,
    buttonStyle,
    promoCode,
    customTosText,
    darkTheme = false,
    tosUrl = '/lp/promotional-terms-join30'
}: Props) => {
    const { t } = useTranslation();

    const isNoPromo = promoCode === Coupons.nopromo;

    const tosText = customTosText || t('generalDiscount.promoBlockLeft.tos');

    const { isReferral } = useReferral();

    if (!!promoCode && isReferral) {
        return null;
    }

    return (
        <FeatureBlockSection
            customClass={`promo-block-left ${
                darkTheme ? 'promo-block-left--dark' : ''
            } ${customClass}`}
        >
            <FeatureBlockContent>
                {titleImage && (
                    <HBMediaElement
                        name={titleImage}
                        customClass="promo-block-left__title-image"
                    />
                )}
                <HBText
                    customClass="promo-block-left__title"
                    tag="h2"
                    type="title-l"
                >
                    {title}
                </HBText>
                {subtitle && (
                    <HBText
                        customClass="promo-block-left__subtitle"
                        tag="h3"
                        type="body-l"
                    >
                        {subtitle}
                    </HBText>
                )}
                {!!promoCode && !isNoPromo && (
                    <HBText
                        tag="p"
                        type="body-l"
                        customClass="promo-block-left__promo-code"
                    >
                        {t('generalDiscount.promoBlockLeft.useCode')}{' '}
                        <b>{promoCode}</b>
                    </HBText>
                )}
                <RegistrationForm
                    size="large"
                    source={source}
                    buttonText={ctaText || t('registrationForm.startFreeTrial')}
                    showNoCcRequired={!customNoCcText}
                    buttonStyle={buttonStyle}
                />
                {customNoCcText && (
                    <p className="promo-block-left__custom-no-cc-text">
                        {customNoCcText}
                    </p>
                )}
                {(!!promoCode && !isNoPromo) ||
                    (customTosText && (
                        <HBText
                            customClass="promo-block-left__tos"
                            tag="p"
                            type="body-xs"
                        >
                            <a href={tosUrl}>{tosText}</a>
                        </HBText>
                    ))}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(PromoBlockLeft);
