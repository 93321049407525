import { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import UtilsService from '../../Services/UtilsService';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import PromoBanner from '../PromoBanner/PromoBanner';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';
import { PROMO_BANNER_EXCLUSION_PATHS } from '../../constants/constants';
import { usePromoCode } from '../../hooks/usePromoCode';
import { MAP_OF_PROMO_CODES_CONFIG } from '../../constants/promoCodes';
import useReferral from '../../hooks/useReferral';
import NavigateLink from '../Link/NavigateLink';

const DiscountBanner = ({ path }: { path?: string }) => {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();
    const [isCurrentUrlInBannerList, setIsCurrentUrlInBannerList] =
        useState(false);
    const { isReferral } = useReferral();
    const breakpoints = useBreakpoint();

    useEffect(() => {
        setIsCurrentUrlInBannerList(
            UtilsService.isCurrentUrlInList(PROMO_BANNER_EXCLUSION_PATHS)
        );
    }, []);

    const onPromoBannerClose = () => {
        AnalyticsService.track(AnalyticsEvents.welcome20_promo_banner_close);
    };

    const title = t(
        `${promoCode}.banner.${
            breakpoints.mediumUp ? 'titleDesktop' : 'titleMobile'
        }`
    );

    const theme = promoCode
        ? MAP_OF_PROMO_CODES_CONFIG[promoCode].bannerTheme
        : undefined;

    const tosLink = MAP_OF_PROMO_CODES_CONFIG[promoCode].tosLink || null;

    const shouldShowBanner = !isReferral && !isCurrentUrlInBannerList;

    return (
        <PromoBanner
            id="2024-01-hb2024save"
            onClose={onPromoBannerClose}
            userCanDismiss
            shouldShowBanner={shouldShowBanner}
            customClass={`promo-banner promo-banner--${promoCode}`}
            theme={theme}
            path={path}
        >
            <div className="promo-banner__text-container">
                <div className="promo-banner__title">{title}</div>

                {tosLink ? (
                    <NavigateLink
                        className="promo-banner__tos-link"
                        source="promo banner"
                        link={tosLink}
                    >
                        {t(`${promoCode}.banner.tosLinkText`)}
                    </NavigateLink>
                ) : (
                    <RegistrationForm
                        customClass="promo-banner-cta"
                        buttonStyle="invisible"
                        source="promo banner"
                        buttonText={t(`${promoCode}.banner.ctaText`)}
                        couponCode={promoCode}
                        size="x-small"
                    />
                )}
            </div>
        </PromoBanner>
    );
};

export default memo(DiscountBanner);
