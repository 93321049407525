import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import UtilsService from '../Services/UtilsService';
import { useEffect, useState } from 'react';
import ApiService from '../Services/ApiService';
import type { TTemplateData } from '../components/PublicTemplatePreview/type';
import '../components/PublicTemplatePreview/template-preview.scss';
import HBSplash from '../components/honeybook-ui/HBSplash/HBSplash';
import { RecipientPageProvider } from '../components/RecipientPage/context/RecipientPageProvider';
import { NotFound } from './404';
import { useTranslation } from 'react-i18next';
import AnalyticsService from '../Services/AnalyticsService';
import { trackError } from '../utils/error.utils';
import { useABTestContext } from '../ab-tests/context/useABTestContext';
import StatsigService from '../Services/StatsigService';
import GeolocationService from '../Services/GeolocationService';
import { NonUsaWarningModalWrapper } from '../components/NonUsaWarningModalWrapper/NonUsaWarningModalWrapper';
import { trackGetTemplateEvent } from '../components/PublicTemplatePreview/utils';
import { PublicTemplatePreviewWrapper } from '../components/PublicTemplatePreview/PublicTemplatePreviewWrapper';

const TemplateNotFoundPage = ({ templateId }: { templateId: string }) => {
    const { t } = useTranslation();

    useEffect(() => {
        AnalyticsService.track('template not found', { templateId });
    }, []);

    return (
        <NotFound
            title={t('templateNotFount.title')}
            subtitle={t('templateNotFount.subtitle')}
            buttonText={t('templateNotFount.buttonText')}
        />
    );
};

const TemplatePreviewPage = ({ templateId }) => {
    const [templateData, setTemplateData] = useState<TTemplateData>();
    const [isLoading, setIsLoading] = useState(false);
    const [shouldShowTemplateNotFoundPage, setShouldShowTemplateNotFoundPage] =
        useState(false);
    const [signupUrlForNonUsaUser, setSignupUrlForNonUsaUser] = useState('');

    const {
        variantPerTest: { public_template_recipient_funnel }
    } = useABTestContext();

    const _templateId =
        templateId || UtilsService.getParameterByName('templateId');

    const fetchTemplateData = async () => {
        try {
            setIsLoading(true);
            const templateDataPromise = ApiService.getTemplateData(_templateId);
            const templatePublicUrlResponsePromise =
                ApiService.getTemplatePublicUrl(_templateId);
            const [templateData, templatePublicUrlResponse] = await Promise.all(
                [templateDataPromise, templatePublicUrlResponsePromise]
            );
            const { is_gallery_template: isGalleryTemplate } =
                templateData.public_configuration;

            AnalyticsService.track('recipient experience test viewed', {
                testVariant: isGalleryTemplate
                    ? 'control'
                    : public_template_recipient_funnel,
                isGalleryTemplate
            });
            if (!isGalleryTemplate) {
                await StatsigService.manuallyLogExperimentExposure(
                    'public_template_recipient_funnel'
                );
            }
            setTemplateData({ ...templateData, ...templatePublicUrlResponse });
        } catch (error) {
            trackError(error, { message: 'recipient funnel loaded error' });
            setShouldShowTemplateNotFoundPage(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!!_templateId) {
            fetchTemplateData();
        }
    }, []);

    const _trackGetTemplateEvent = () => {
        trackGetTemplateEvent({
            allow_save: templateData?.public_configuration.allow_save!,
            id: templateData?._id!
        });
    };

    const clickUseTemplateSubscriber = ({
        data: { signupUrl: _signupUrlFromIframe }
    }) => {
        if (!_signupUrlFromIframe) {
            return;
        }
        GeolocationService.isFromValidCountriesPromise().then(
            async (isValid: boolean): Promise<void> => {
                if (isValid) {
                    _trackGetTemplateEvent();
                    window.location.href = _signupUrlFromIframe;
                } else {
                    setSignupUrlForNonUsaUser(_signupUrlFromIframe);
                }
            }
        );
    };

    const onConfirmNonUsaModal = () => {
        _trackGetTemplateEvent();
        window.location.href = signupUrlForNonUsaUser;
    };

    const onCloseNonUsaModal = () => setSignupUrlForNonUsaUser('');

    const shouldShowNonUsaUserModal = !!signupUrlForNonUsaUser;

    if (isLoading || (!templateData && !shouldShowTemplateNotFoundPage)) {
        return <HBSplash />;
    }

    if (shouldShowTemplateNotFoundPage) {
        return <TemplateNotFoundPage templateId={_templateId} />;
    }

    const ogTags = (() => {
        const {
            title,
            public_configuration: { is_gallery_template }
        } = templateData!;

        const _title = is_gallery_template
            ? `HoneyBook template - ${title}`
            : 'Check out my HoneyBook template!';

        return {
            url: 'https://www.honeybook.com/template-preview',
            title: _title,
            description: is_gallery_template
                ? undefined
                : 'Save time and impress your clients with my HoneyBook template',
            noIndex: true
        };
    })();

    return (
        <Layout ogTags={ogTags} noFooter noPromo noNav>
            <RecipientPageProvider templateData={templateData!}>
                <PublicTemplatePreviewWrapper
                    clickUseTemplateSubscriber={clickUseTemplateSubscriber}
                />
            </RecipientPageProvider>
            <NonUsaWarningModalWrapper
                isOpenNonUsaModal={shouldShowNonUsaUserModal}
                onCloseNonUsaModal={onCloseNonUsaModal}
                onConfirmNonUsaModal={onConfirmNonUsaModal}
            />
        </Layout>
    );
};

export default TemplatePreviewPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["templatePreview", "common"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
