import { useCallback, useEffect, useRef, useState } from 'react';
import { useSubscribeToIframeEvent } from './useSubscribeToIframeEvent';
import { TScreenMode } from '../PublicTemplatePreview/type';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useRecipientPageContext } from './context/recipient-page-context';
import AnalyticsService from '../../Services/AnalyticsService';
import { trackGetTemplateEvent } from '../PublicTemplatePreview/utils';

const ANIMATION_PROPERTIES = [
    'top',
    'bottom',
    'right',
    'left',
    'width',
    'height'
] as const;

export const useRecipientPage = () => {
    const [screenMode, setScreenMode] = useState<TScreenMode>('Desktop');
    const [signupRedirectUrl, setSignupRedirectUrl] = useState('');
    const iframeContainerRef = useRef<HTMLDivElement>(null);
    const { postMessageToChild, templateData, isIframeLoaded } =
        useRecipientPageContext();
    const { largeUp } = useBreakpoint();
    const resolveRef = useRef<(value?: unknown) => void>();

    const onViewSelect = useCallback((_screenMode: TScreenMode) => {
        setScreenMode(_screenMode);
        AnalyticsService.track('change public template preview screen mode', {
            screen_mode: _screenMode
        });
    }, []);

    const handlePreviewTemplate = useCallback(() => {
        if (!isIframeLoaded) {
            return;
        }
        AnalyticsService.track('click: preview template', {
            templateId: templateData._id
        });
        postMessageToChild({ event: 'preview_clicked' });
        const iframeContainerElement = iframeContainerRef.current!;
        const boundingProperties =
            iframeContainerElement.getBoundingClientRect();
        ANIMATION_PROPERTIES.forEach(property => {
            const { [property]: value } = boundingProperties;
            iframeContainerElement.style[property] = `${value}px`;
        });

        requestAnimationFrame(() => {
            iframeContainerElement.classList.add('full-screen-preview');
            iframeContainerElement.classList.add('visible');
        });
    }, [postMessageToChild, isIframeLoaded, templateData]);

    const onBackButtonClick = useCallback(() => {
        const iframeContainerElement = iframeContainerRef.current!;
        iframeContainerElement.classList.remove('visible');
        setTimeout(
            () => {
                iframeContainerElement.classList.remove('full-screen-preview');
                ANIMATION_PROPERTIES.forEach(property => {
                    requestAnimationFrame(() => {
                        iframeContainerElement.style.removeProperty(property);
                    });
                });
            },
            largeUp ? 450 : 0
        );
    }, [largeUp]);

    useSubscribeToIframeEvent({
        eventName: 'back_button_clicked',
        subscriber: onBackButtonClick
    });

    useSubscribeToIframeEvent({
        eventName: 'signup_redirect_url_update',
        subscriber: ({ data: { signupRedirectUrl } }) => {
            setSignupRedirectUrl(signupRedirectUrl);
        }
    });

    useEffect(() => {
        if (!!signupRedirectUrl && resolveRef.current) {
            resolveRef.current();
        }
    }, [signupRedirectUrl]);

    const handleGetTemplate = useCallback(() => {
        if (!isIframeLoaded) {
            return;
        }
        trackGetTemplateEvent({
            allow_save: templateData.public_configuration.allow_save!,
            id: templateData._id,
            data: { screen_mode: screenMode }
        });
        postMessageToChild({ event: 'get_template_clicked' });
        return new Promise(resolve => {
            resolveRef.current = resolve;
        });
    }, [postMessageToChild, isIframeLoaded, screenMode, templateData]);

    return {
        handleGetTemplate,
        handlePreviewTemplate,
        onViewSelect,
        screenMode,
        signupRedirectUrl,
        iframeContainerRef,
        isIframeLoaded
    };
};
